.fenetre-principale {
    //les divs doivent etre cote a cote
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    width: 100%;

}

.editeur {
    width: 45%;
    margin: 10px;
    height: -webkit-fill-available;

    min-height: 500px;

    .editeur-wrapper {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }
}

.markdown {
    textarea {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }

    width: 45%;
    margin: 10px;
}


.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 500px !important;

}

.demo-wrapper-medium {
    width: 650px !important;
    display: block !important;
    margin-bottom: 50px !important;
    height: 300px !important;
}

.demo-editor {
    height: 375px !important;
    border: 2px solid #d0d0d0 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.wrapper-class {
    height: 100% !important;
}

.editor-class {
    height: 100% !important;
    min-height: 400px !important;
    border: 1px solid #d0d0d0 !important;
    //limiter la taille pour avoir un scroll
    max-height: 500px !important;
    max-width: 100% !important;
}